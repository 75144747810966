'use strict';
import Modal from './modal';
import moment from 'moment';

class Util {
	getAmountKr = (amount) => {
		if (amount.length === 0) {
			return '';
		}
		const counter = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
		const spliter = ['', '십', '백', '천'];
		const unit = ['', '만', '억', '조', '경', '해'];

		const amountStr = '' + amount;

		if (amountStr === '0') {
			return '영';
		}
		let breaker = false;
		let result = '';
		for (let i = 0; i < unit.length; i++) {
			let unitUsed = false;
			for (let j = 0; j < spliter.length; j++) {
				const index = i * spliter.length + j;
				if (index === amountStr.length) {
					breaker = true;
					break;
				}
				const letter = amountStr[amountStr.length - 1 - index];
				if (letter === '0') {
					continue;
				}
				if (letter === '-') {
					continue;
				}
				let str = counter[+letter];
				str += spliter[j];
				if (unitUsed === false) {
					str += unit[i];
					unitUsed = true;
				}
				result = str + result;
			}
			if (breaker) {
				break;
			}
		}
		return result;
	};
	createAmountInput = (input, krInput) => {
		input = $(input);
		if (krInput) {
			krInput = $(krInput);
		}
		input.amount = () => {
			const text = input.val();
			let result = '';
			for (let i = 0; i < text.length; i++) {
				const letter = text[i];
				if (letter === '-' && i === 0) {
					result += '-';
					continue;
				}
				if (letter === ' ') {
					continue;
				}
				if (Number.isNaN(+letter)) {
					continue;
				}
				result += letter;
			}
			if (result === '-' || result.length === 0) {
				return '';
			}
			if (result.length > 17) {
				Util.alert('해당 숫자는 너무 큰 값입니다. 10경 미만의 단위를 사용하여 주시기 바랍니다.');
				return '';
			}
			return result * 1;
		};
		input.refresh = () => {
			input.val(input.amount().toLocaleString());
			if (krInput && krInput.length > 0) {
				krInput.val(this.getAmountKr(input.amount()));
			}
		};
		input.on('focusout', (e) => {
			input.refresh();
		});
		input.on('keydown', (e) => {
			if (e.key === 'Enter') {
				e.preventDefault();
				input.refresh();
			}
		});
		if (krInput && krInput.length > 0) {
			input.on('change', (e) => {
				krInput.val(this.getAmountKr(input.amount()));
			});
		}

		return input;
	};

	modal = (options) => {
		return new Modal(options);
	};
	datepicker = (input) => {
		const dayNames = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
		const dayNamesMin = ['일', '월', '화', '수', '목', '금', '토'];
		const dayNamesShort = ['일', '월', '화', '수', '목', '금', '토'];
		const monthNames = [];
		const monthNamesShort = [];
		for (let i = 1; i <= 12; i++) {
			monthNames.push(`${i}`.padStart(2, '0') + '월');
			monthNamesShort.push(i + '월');
		}
		input.datepicker({
			dateFormat: 'yy-mm-dd',
			showButtonPanel: true,
			currentText: '오늘',
			closeText: '닫기',
			dayNames: dayNames,
			dayNamesMin: dayNamesMin,
			dayNamesShort: dayNamesShort,
			monthNames: monthNames,
			monthNamesShort: monthNamesShort,
			showMonthAfterYear: true,
			yearSuffix: '년',
			beforeShow: () => {
				$('body > style[name^="pos_style"]').remove();
				const top = input.offset().top - $(document).scrollTop() + input.outerHeight();
				const style = $(`<style name="pos_style_${input.attr('name')}">`).text(`#ui-datepicker-div { top : ${top}px !important; }`);
				$('body').append(style);
			},
			onClose: () => {
				setTimeout(() => {
					$(`body > style[name="pos_style_${input.attr('name')}"]`).remove();
				}, 250);
			},
		});
		input.on('focusout', (e) => {
			const value = input.val();
			if (value.length === 8 && !Number.isNaN(+value)) {
				const year = value.substring(0, 4);
				const month = value.substring(4, 6);
				const day = value.substring(6, 8);
				const date = moment(`${year}-${month}-${day}`);
				if (date.format('YYYYMMDD') === value) {
					input.val(date.format('YYYY-MM-DD'));
				}
			}
		});
	};
	alert = (options) => {
		if (typeof options === 'string') {
			options = {
				message: options,
			};
		}

		const alertModal = new Modal({
			name: options.name ? options.name : '',
			width: options.width ? options.width : '480px',
			height: options.height ? options.height : '280px',
			fixed: true,
			clazz: 'hc-alert-modal',
			afterClose: () => {
				if (typeof options.callback === 'function') {
					options.callback();
				}
			},
		});

		alertModal.open((modal) => {
			const modalBody = modal.find('.hc-modal-body');
			modalBody.focus();
			modalBody.html(options.message);
			const buttonContent = $('<div>').addClass('dialog-button-content');
			const closeButton = $('<button class="ui secondary button">').text('닫기');
			closeButton.on('click', (e) => {
				e.preventDefault();
				alertModal.close();
			});
			buttonContent.append(closeButton);
			modalBody.append(buttonContent);
			closeButton.focus();
		});
	};
	confirm = (options) => {
		const confirmModal = new Modal({
			name: options.name ? options.name : '',
			width: options.width ? options.width : '480px',
			height: options.height ? options.height : '280px',
			fixed: true,
			clazz: 'hc-confirm-modal',
		});

		confirmModal.open((modal) => {
			const modalBody = modal.find('.hc-modal-body');
			modalBody.html(options.message);
			const buttonContent = $('<div>').addClass('dialog-button-content');
			const confirmButton = $('<button class="ui primary button">').text('확인');
			confirmButton.on('click', (e) => {
				e.preventDefault();
				if (typeof options.callback === 'function') {
					options.callback(modalBody);
				}
				confirmModal.close();
			});

			const closeButton = $('<button class="ui secondary button">').text('취소');
			closeButton.on('click', (e) => {
				e.preventDefault();
				if (typeof options.cancelCallback === 'function') {
					options.cancelCallback();
				}
				confirmModal.close();
			});
			buttonContent.append(confirmButton);
			buttonContent.append(closeButton);
			modalBody.append(buttonContent);
			closeButton.focus();
		});
	};
}
const instance = new Util();

export default instance;
