'use strict';
import Util from 'util';

const instance = {
	gnb: $('#gnb'),
	gnb_bg: $('#gnb_bg'),
	target: $('#app_header'),
	desktop: $('#desktop_header'),
	mobile: $('#mobile_header'),
};

instance.gnb.on('hover-on', (e) => {
	instance.gnb_bg.css('display', 'block');
	const subGNBList = instance.gnb.find('.gnb-sub');
	for (let i = 0; i < subGNBList.length; i++) {
		const subGNB = $(subGNBList[i]);
		subGNB.css('display', 'block');
		if (instance.gnb_bg.height() < subGNB.height() + 50) {
			instance.gnb_bg.height(subGNB.height() + 50);
		}
	}
});

instance.gnb.on('hover-off', (e) => {
	instance.gnb_bg.css('display', 'none');
	instance.gnb.find('.gnb-sub').css('display', 'none');
});

instance.gnb.on('mouseover', (e) => {
	instance.gnb.trigger('hover-on');
});

instance.target.find('.center-container').on('mouseleave', (e) => {
	instance.gnb.trigger('hover-off');
});

instance.gnb.on('redirect', (e, params) => {
	const categoryList = instance.gnb.children('li');
	const itemList = $(categoryList[params.category]).find('.gnb-sub > li');
	const nav = $(itemList[params.item]).children('a');
	nav.trigger('click');
});

instance.mobile.header = instance.mobile.find('.header-navigation');
instance.mobile.open = instance.mobile.find('.mobile-menu');
instance.mobile.close = instance.mobile.header.find('[name="close"]');

instance.mobile.open.on('click', (e) => {
	e.preventDefault();
	instance.mobile.header.addClass('header-navigation-open');
	$('body').css({ 'overflow-y': 'hidden' });
});
instance.mobile.close.on('click', (e) => {
	e.preventDefault();
	instance.mobile.header.removeClass('header-navigation-open');
	$('body').css({ 'overflow-y': 'auto' });
});

const mobileOpenerList = instance.mobile.header.find('[name="opener"]');
for (let i = 0; i < mobileOpenerList.length; i++) {
	const opener = $(mobileOpenerList[i]);
	opener.on('touchend click', (e) => {
		e.preventDefault();
		const li = opener.parent();
		if (li.hasClass('selected')) {
			li.removeClass('selected');
		} else {
			const ul = opener.closest('ul');
			ul.children('li.selected').removeClass('selected');
			li.addClass('selected');
		}
	});
}

instance.target.find('[name="myAccount"]').on('click', (e) => {
	e.preventDefault();
	const modal = Util.modal({
		width: '560px',
		height: '592px',
		clazz: 'myaccount-edit-modal',
		name: '계정정보 수정',
	});
	$.ajax({
		method: 'get',
		url: '/user/detail',
		async: false,
	})
		.done((response) => {
			modal.open((ele) => {
				const modalBody = ele.find('.hc-modal-body');
				modalBody.html(response);

				modalBody.find('[name="cancel"]').on('click', (e) => {
					modal.close();
				});

				modalBody.find('[name="submit"]').on('click', (e) => {
					const message = modalBody.find('[name="message"]');
					const password = modalBody.find('[name="password"]').val();
					const newPassword = modalBody.find('[name="newPassword"]').val();
					const newPasswordChecking = modalBody.find('[name="passwordChecking"]').val();
					const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@!%*#?&])[A-Za-z\d$@!%*#?&]{8,}$/;

					if (!passwordRegExp.test(newPasswordChecking.trim())) {
						message.text('신규 비밀번호 확인은 영문, 숫자, 특수문자 조합 8자리 이상으로 입력해주세요.');
						return;
					}

					if (newPassword.trim() !== newPasswordChecking.trim()) {
						message.text('신규 비밀번호 확인이 일치하지 않습니다.');
						return;
					}

					$.ajax({
						method: 'patch',
						url: '/api/user/password',
						data: {
							password: password.trim(),
							newPassword: newPassword.trim(),
						},
						async: false,
					})
						.done(() => {
							// TODO: security invalidate before done response
							Util.alert({
								message: '비빌번호 변경이 완료되었습니다. 로그아웃합니다.',
								callback: () => {
									window.location.href = '/api/signout';
								},
							});
						})
						.fail((error) => {
							if (error.status === 405) {
								Util.alert({
									message: '세션이 만료되었습니다. 로그인이 필요합니다.',
									callback: () => {
										window.location.href = '/signin';
									},
								});
							} else if (error.status === 403 || error.status === 401) {
								message.text(error.responseText);
							} else {
								message.text('잘못된 요청입니다.');
							}
						});
				});
				modalBody.find('[name="apply"]').on('click', (e) => {
					e.preventDefault();
					openWithdrawalModal();
				});
			});
		})
		.fail((error) => {
			if (error.status === 405) {
				Util.alert({
					message: '세션이 만료되었습니다. 로그인이 필요합니다.',
					callback: () => {
						window.location.href = '/signin';
					},
				});
			}
		});
});

const openWithdrawalModal = () => {
	const modal = Util.modal({
		width: '560px',
		height: '824px',
		clazz: 'quit-modal',
		name: '탈퇴 신청',
	});
	$.ajax({
		method: 'get',
		url: '/user/withdrawal',
		async: false,
	})
		.done((response) => {
			modal.open((ele) => {
				const modalBody = ele.find('.hc-modal-body');
				modalBody.html(response);
				const reasonCell = modalBody.find('[name="reason"]');

				$(modalBody.find('.checkbox')).checkbox({
					onChange: () => {
						if (modalBody.find('.checkbox.checked > [type="radio"]').val() === '기타') {
							reasonCell.prop('disabled', false);
						} else {
							reasonCell.prop('disabled', true);
							reasonCell.val('');
						}
					},
				});

				modalBody.find('[name="submit"]').on('click', (e) => {
					e.preventDefault();
					const message = modalBody.find('[name="message"]');
					const password = modalBody.find('[name="password"]').val();

					if (modalBody.find('[type="radio"][name="quit"]:checked').length === 0) {
						message.text('탈퇴 사유를 선택해주세요.');
						return;
					}

					// NOTE: 개발 과정에서 비밀번호 특문 규칙 무효
					// const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@!%*#?&])[A-Za-z\d$@!%*#?&]{8,}$/;
					const passwordRegExp = {
						test: (value) => {
							return value !== '';
						},
					};

					if (!passwordRegExp.test(password.trim())) {
						message.text('비밀번호는 영문, 숫자, 특수문자 조합 8자리 이상으로 입력해주세요.');
						return;
					}

					let reason = modalBody.find('[type="radio"][name="quit"]:checked').val();
					if (reason === '기타') {
						reason += ': ' + reasonCell.val();
					}

					$.ajax({
						method: 'patch',
						url: '/api/user/withdrawal',
						data: {
							reason: reason,
							password: password.trim(),
						},
						async: false,
					})
						.done(() => {
							window.location.href = '/api/signout';
						})
						.fail((error) => {
							if (error.status === 405) {
								Util.alert({
									message: '세션이 만료되었습니다. 로그인이 필요합니다.',
									callback: () => {
										window.location.href = '/signin';
									},
								});
							} else if (error.status === 403) {
								modalBody.find('[name="message"]').text('잘못된 비밀번호입니다.');
							}
						});
				});

				modalBody.find('[name="cancel"]').on('click', (e) => {
					e.preventDefault();
					modal.close();
				});
			});
		})
		.fail((error) => {
			if (error.status === 405) {
				Util.alert({
					message: '세션이 만료되었습니다. 로그인이 필요합니다.',
					callback: () => {
						window.location.href = '/signin';
					},
				});
			}
		});
};
