'use strict';

import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';

export default class Modal {
	constructor(option) {
		console.info('modal is initializing');
		this.resizeHandler = null;
		this.opt = option;

		// class
		this.opt.screenClass = 'hc-modal-screen';
		this.opt.modalClass = 'hc-modal-container cal-no-select';
		if (this.opt.clazz) {
			this.opt.modalClass += ' ' + this.opt.clazz;
		}
		if (!this.opt.name) {
			this.opt.name = '';
		}
		if (typeof this.opt.backgroundClickToClose === 'undefined') {
			this.opt.backgroundClickToClose = false;
		}

		this.renderModal();
		this.renderScreen();
	}

	renderHeader = () => {
		this.header = $('<div>').addClass('hc-modal-header');
		this.header.append($('<span>').text(this.opt.name));
		// modal head - close button
		if (!this.opt.hideCloseButton) {
			this.closeButton = $('<button>').addClass('hc-modal-close');
			this.closeButton.append($('<img>').attr('src', '/image/modal_icon_close.png'));
			this.closeButton.bind('touchend click', (e) => {
				e.preventDefault();
				this.close();
			});
			this.header.append(this.closeButton);
		}
		this.modal.append(this.header);
	};

	renderModal = () => {
		this.modalId = 'modal_' + new Date().getTime();

		this.modal = $('<modal>').addClass(this.opt.modalClass);
		this.modal.attr('id', this.modalId);
		this.modal.css({
			width: this.opt.width,
			'max-width': 'inherit',
			height: this.opt.height,
			'max-height': $(window).height() > 1280 ? '1280px' : 'inherit',
			opacity: 0,
		});

		// close event handler
		this.modal.on('close', () => {
			this.close();
		});

		this.renderHeader();
		this.body = $('<div>').addClass('hc-modal-body');

		this.footer = $('<div>').addClass('hc-modal-footer');
		this.modal.append(this.body);
		this.modal.append(this.footer);
	};

	renderScreen = () => {
		// 현재 모달이 생성되기 전의 모달 수
		this.modalCnt = $('modal.hc-modal-container').length;

		this.screen = $('<div>').addClass(this.opt.screenClass);

		// blur screen
		if (this.opt.blurMode) {
			if (this.opt.blurTarget) {
				$(this.opt.blurTarget).addClass('hc-modal-blurmode');
			}
			$('.hc-modal-screen').addClass('hc-modal-blurmode');
		}
		// multi modal option
		if (this.opt.multi && this.modalCnt > 0) {
			this.screen = $('.hc-modal-screen').last();
		} else {
			this.screen.css({ opacity: 0 });
		}

		// close modal when click screen
		if (this.opt.backgroundClickToClose !== false) {
			this.screen.on('touchstart mousedown', (e) => {
				if ($(e.target).hasClass('hc-modal-screen')) {
					this.close();
				}
			});
		}

		this.screen.append(this.modal);
	};

	locateModal = () => {
		if (this.modal.hasClass('hc-image-preview')) {
			console.warn('image preview mode');
			// 이미지 미리보기 모달에서 위치 결정함(이미지 로드 후 사이즈에 따라 조정이 필요함)
			return;
		}

		let itemCnt = $('.item-multi-selection').length;
		this.modalCnt = $('modal.hc-modal-container').length;

		let h = this.modal.height();
		let w = this.modal.width();
		let ww = $(window).width();
		let wh = $(window).height();

		if (!window.app || !window.app.mobile) {
			if (h > wh) {
				h = Math.round(wh * 0.9);
				this.modal.css({ height: h + 'px' });
				console.warn('modal height is greater than window height. adjust height');
			} else {
				let orgHeight = parseInt(this.opt.height.replace('px', ''));
				if (h < orgHeight && orgHeight < wh) {
					this.modal.css({ height: orgHeight + 'px' });
					h = orgHeight;
				}
			}

			if (w > ww) {
				w = Math.round(ww * 0.9);
				this.modal.css({ width: w + 'px' });
				console.warn('modal width is greater than window width. adjust width');
			} else {
				let orgWidth = parseInt(this.opt.width.replace('px', ''));
				if (w < orgWidth && orgWidth < ww) {
					this.modal.css({ width: orgWidth + 'px' });
					w = orgWidth;
				}
			}
		}

		if ((w + '').indexOf('%') > -1) {
			w = Math.round(parseInt(w.replace('%', '')) / 100.0) * ww;
		}

		let rh, rw;
		if (this.opt.multi) {
			//다중 모달 지원 (sibling 타입)
			rh = h / 2 + this.modalCnt;
			rw = w / 2 + this.modalCnt * 30;

			let left = (w * this.modalCnt + 15) * this.modalCnt;
			let finalWidth = (w + 15) * itemCnt;
			let leftOffset = (ww - finalWidth) / 2;

			if (finalWidth > ww) {
				w = ww / 2 - 15;
				left = (w * this.modalCnt + 15) * this.modalCnt;
				finalWidth = (w + 15) * itemCnt - 15;
				leftOffset = (ww - finalWidth) / 2;

				this.modal.css({
					width: ww / 2 - 15 + 'px',
					top: 'calc(50vh - ' + rh + 'px)',
					left: left + leftOffset + 'px',
				});
			} else {
				this.modal.css({
					top: 'calc(50vh - ' + rh + 'px)',
					left: left + leftOffset + 'px',
				});
			}
		} else {
			if (window.app && window.app.mobile) {
				this.modal.css({
					top: '0px',
					left: '0px',
				});
			} else if (window.app && window.app.tablet) {
				//다중 모달 팝업 대응(stack 타입)
				rh = h / 2 + this.modalCnt * 5;
				rw = w / 2; //+modalCnt*5;

				this.modal.css({
					top: 'calc(50vh - ' + rh + 'px)',
					left: 'calc(50vw - ' + rw + 'px)',
				});
			} else {
				//다중 모달 팝업 대응(stack 타입)
				rh = h / 2 + this.modalCnt * 20;
				rw = w / 2 + this.modalCnt * 20;

				this.modal.css({
					top: 'calc(50vh - ' + rh + 'px)',
					left: 'calc(50vw - ' + rw + 'px)',
				});
			}
		}
	};

	open = (callback) => {
		console.info('open modal: ' + this.modalId);
		console.debug('body - style: overflow-y goes hidden');
		this.bodyPrevOverflowY = $('body').css('overflow-y');
		$('body').css('overflow-y', 'hidden');

		if (!this.opt.hideCloseButton) {
			$(window)
				.off('keydown')
				.on('keydown', (e) => {
					// esc key
					if (e.keyCode === 27 || e.which == 27) {
						e.preventDefault();
						e.stopPropagation();

						//// 다이알로그 먼저 처리
						const dialog = $('.dialog-visible');
						if (dialog.length > 0) {
							dialog.find('.dialog-close').click();
						} else {
							this.close();
							const screen = $('.hc-modal-screen');

							if (screen.length === 0) {
								$(this).off();
							}
						}
					}
				});
		}

		//다중 모달 지원 (stack, sibling 타입)
		if (this.opt.multi) {
			if (this.modalCnt === 0) {
				$('body').prepend(this.screen);
			}
		} else {
			$('body').append(this.screen);
		}

		this.screen.addClass('hc-effect-fadein-100ms');
		setTimeout(() => {
			// 모달 위치 지정
			this.locateModal();

			// 모달 이동 가능 여부 처리
			if (!window.app || (!window.app.mobile && !window.app.tablet)) {
				if (this.opt.fixed !== true) {
					this.modal
						.draggable({
							containment: '.hc-container',
							handle: '.hc-modal-header',
							iframeFix: true,
						})
						.resizable()
						.on('resize', (e) => {
							e.stopPropagation();
							e.preventDefault();
						});
				} else {
					this.header.css({ cursor: 'default' });
				}
			}

			setTimeout(() => {
				this.screen.addClass('done');
				this.modal.css({ opacity: 1 });

				if (window.app && !window.app.tablet && !window.app.mobile) {
					$(window).on('resize.modal2', () => {
						if (this.resizeHandler == null) {
							this.modal.hide();
							this.modal.css({
								opacity: 0,
								transition: 'opacity 0.1s',
							});
						} else {
							clearTimeout(this.resizeHandler);
						}

						this.resizeHandler = setTimeout(() => {
							// 모달 위치 갱신
							this.locateModal();

							setTimeout(() => {
								this.modal.show();
								this.modal.css({
									opacity: 1,
									transition: 'opacity 0.2s',
								});
							}, 250);

							this.resizeHandler = null;
						}, 100);
					});
				}

				// 모바일 환경에서 칸반 스크롤시 카드 클릭되는 문제 처리
				if (window.app && (window.app.mobile || window.app.tablet)) {
					this.body.scroll(() => {
						window.app.scrolling = true;

						if (window.app.scroll) {
							clearTimeout(window.app.scroll);
						}

						window.app.scroll = setTimeout(() => {
							window.app.scrolling = false;
						}, 500);
					});
				}
			}, 100);

			if (typeof callback === 'function') {
				callback(this.modal);
			}
		}, 0);
	};

	close = (callback) => {
		if (typeof this.opt.beforeClose === 'function') {
			if (this.opt.beforeClose(this.body) === false) {
				return;
			}
		}

		if (this.bodyPrevOverflowY) {
			$('body').css('overflow-y', this.bodyPrevOverflowY);
			delete this.bodyPrevOverflowY;
			$('body').css('width', '100%');
		}

		// 모달 기능에 종속된 위젯 제거
		this.modal.find('.hc-modal-depend-widget__js').remove();
		$('.item-multi-selection').removeClass('item-multi-selection');
		$('#ui-datepicker-div').hide();
		this.modal.addClass('hc-effect-fadeout-200ms');

		setTimeout(() => {
			this.screen.addClass('hc-effect-fadeout-100ms');

			if (this.opt.blurMode) {
				$('.hc-modal-screen').removeClass('hc-modal-blurmode');

				if (this.opt.blurTarget) {
					$(this.opt.blurTarget).removeClass('hc-modal-blurmode');
				}
			}

			setTimeout(() => {
				this.screen.remove();
				if (typeof this.opt.afterClose === 'function') {
					this.opt.afterClose();
				}
			}, 100);

			if (typeof callback === 'function') {
				callback();
			}
		}, 0);
	};
}
